import { maxLength } from '@vuelidate/validators'

export const INPUT_TYPES = {
  NUMBER: 'NUMBER',
  TEXT: 'TEXT'
}

export const INPUT_TYPES_REGX_PROPS = {
  [INPUT_TYPES.NUMBER]: ['maxDigits', 'decimal']
}

export const INPUT_TYPES_REGX_MAP = {
  [INPUT_TYPES.NUMBER]: (maxDigits = 12, decimal) => new RegExp(`^\\d{0,${maxDigits}}[.]?\\d{0,${decimal}}$`),
  [INPUT_TYPES.TEXT]: maxLength => new RegExp(`^.${maxLength ? '{0,' + maxLength + '}' : '*'}$`)
}

export const WINDOW_MODE_NAMES = {
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
  DESKTOP: 'DESKTOP',
  DESKTOP_1280: 'DESKTOP_1280',
  DESKTOP_1920: 'DESKTOP_1920'
}

export const WINDOW_MODE_SIZES = {
  [WINDOW_MODE_NAMES.MOBILE]: 360,
  [WINDOW_MODE_NAMES.TABLET]: 768,
  [WINDOW_MODE_NAMES.DESKTOP]: 1024,
  [WINDOW_MODE_NAMES.DESKTOP_1280]: 1280,
  [WINDOW_MODE_NAMES.DESKTOP_1920]: 1920,
}

export const LANG_NAMES = {
  RU: 'ru',
  EN: 'en',
  // CN: 'zh'
}

export const LANGS = Object.values(LANG_NAMES)

export const COUNTRY_FLAGS = [
  {
    icon: require('@/assets/images/icons/flags/usa.svg'),
    title: 'English',
    value: LANG_NAMES.EN
  },
  {
    icon: require('@/assets/images/icons/flags/rus.svg'),
    title: 'Russian',
    value: LANG_NAMES.RU
  },
  // {
  //   icon: require('@/assets/images/icons/flags/chn.svg'),
  //   title: 'Chinese',
  //   value: LANG_NAMES.CN
  // },
]

export const COUNTRY_FLAGS_MAP = {
  [LANG_NAMES.EN]: {
    icon: require('@/assets/images/icons/flags/usa.svg'),
    title: 'English'
  },
  [LANG_NAMES.RU]: {
    icon: require('@/assets/images/icons/flags/rus.svg'),
    title: 'Russian'
  },
  // [LANG_NAMES.CN]: {
  //   icon: require('@/assets/images/icons/flags/chn.svg'),
  //   title: 'Chinese'
  // }
}

export const SYSTEM_USERS = {
  ROOT: 'a00010000001',
  BEGIN: 'a00020000001',
  END: 'a00030000001',
  LOCK: 'a00040000001',
  LOCK_COMMISSION: 'a00050000001',
  SYSTEM_COMMISSION: 'a00060000001',
  LION: 'a08880000001'
}

export const PROCESS_TYPES = {
  AUTOEXCHANGE: 'AUTOEXCHANGE',
  EXCHANGE_WITH_BOTH_COMMISSION: 'EXCHANGE_WITH_BOTH_COMMISSION',
  TRANSFER_SENDER_COMMISSION: 'TRANSFER_SENDER_COMMISSION',
  TRANSFER_RECEIVER_COMMISSION: 'TRANSFER_RECEIVER_COMMISSION'
}

export const TRANSACTION_TYPES = {
  output: 'OUTPUT',
  input: 'INPUT'
}

export const SYSTEM_USER_IDS = Object.values(SYSTEM_USERS)

export const SYSTEM_USER_IDS_MAP = SYSTEM_USER_IDS.reduce((acc, userId) => {
  acc[userId] = userId
  return acc
}, {})

export const FAQ_CATEGORY_NAMES = {
  ALL: 'ALL',
  COMMON: 'COMMON',
  INPUT: 'INPUT',
  OUTPUT: 'OUTPUT',
  TRANSFER: 'TRANSFER',
  EXCHANGE: 'EXCHANGE',
  BUY_CRYPTO: 'BUY_CRYPTO',
  CASH: 'CASH'
}

export const FAQ_CATEGORY_LOCALE_KEYS = {
  [FAQ_CATEGORY_NAMES.ALL]: 'faq_page_cat_all',
  [FAQ_CATEGORY_NAMES.COMMON]: 'faq_page_cat_common',
  [FAQ_CATEGORY_NAMES.INPUT]: 'faq_page_cat_input',
  [FAQ_CATEGORY_NAMES.OUTPUT]: 'faq_page_cat_output',
  [FAQ_CATEGORY_NAMES.TRANSFER]: 'faq_page_cat_transfer',
  [FAQ_CATEGORY_NAMES.EXCHANGE]: 'faq_page_cat_exchange',
  [FAQ_CATEGORY_NAMES.BUY_CRYPTO]: 'faq_page_cat_buy_crypto',
  [FAQ_CATEGORY_NAMES.CASH]: 'faq_page_cat_buy_cash'
}

export const FAQ_CATEGORIES = Object.values(FAQ_CATEGORY_NAMES)

export const FAQ_ITEMS = [
  {
    question: 'faq_page_q_1',
    mobile: {
      answer: [
        {
          text: 'faq_page_a_1_1',
          children: [
            {
              text: 'faq_page_a_1_1_1'
            },
            {
              text: 'faq_page_a_1_1_2'
            },
            {
              text: 'faq_page_a_1_1_3'
            },
            {
              text: 'faq_page_a_1_1_4'
            },
            {
              text: 'faq_page_a_1_1_5'
            },
            {
              text: 'faq_page_a_1_1_6',
              images: [
                'a_1_1_6_1.png',
                'a_1_1_6_2.png'
              ]
            },
          ],
        },
        {
              text: 'faq_page_a_1_2',
              children: [
                {
                  text: 'faq_page_a_1_2_1'
                },
                {
                  text: 'faq_page_a_1_2_2'
                },
                {
                  text: 'faq_page_a_1_2_3'
                },
                {
                  text: 'faq_page_a_1_2_4',
                  images: ['a_1_2_4_1.png']
                },
              ]
            },
        {
          text: 'faq_page_a_1_3'
        }
      ],
    },
    desktop: {
      answer: [
        {
          text: 'faq_page_a_1_1',
          children: [
            {
              text: 'faq_page_a_1_1_1'
            },
            {
              text: 'faq_page_a_1_1_2'
            },
            {
              text: 'faq_page_a_1_1_3'
            },
            {
              text: 'faq_page_a_1_1_4'
            },
            {
              text: 'faq_page_a_1_1_5',
              images: [
                'a_1_1_5.png'
              ]
            },
            {
              text: 'faq_page_a_1_1_6',
              images: ['a_1_1_6_d.jpg']
            },
          ],
        },
        {
          text: 'faq_page_a_1_2',
          children: [
            {
              text: 'faq_page_a_1_2_1'
            },
            {
              text: 'faq_page_a_1_2_2'
            },
            {
              text: 'faq_page_a_1_2_3'
            },
            {
              text: 'faq_page_a_1_2_4',
              images: ['a_1_2_4_d.png']
            },
          ]
        },
        {
          text: 'faq_page_a_1_3'
        }
      ],
    },
    category: FAQ_CATEGORY_NAMES.COMMON
  },
  {
    question: 'faq_page_q_2',
    mobile: {
      answer: [
        {
          text: 'faq_page_a_2_1',
          children: [
            {
              text: 'faq_page_a_2_1_1'
            },
            {
              text: 'faq_page_a_2_1_2'
            },
            {
              text: 'faq_page_a_2_1_3'
            },
            {
              text: 'faq_page_a_2_1_4',
              images: ['a_2_1_4.png']
            },
          ],
        }
      ]
    },
    desktop: {
      answer: [
        {
          text: 'faq_page_a_2_1',
          children: [
            {
              text: 'faq_page_a_2_1_1'
            },
            {
              text: 'faq_page_a_2_1_2'
            },
            {
              text: 'faq_page_a_2_1_3',
              images: ['a_2_1_3_d.png']
            },
          ],
        }
      ],
    },
    category: FAQ_CATEGORY_NAMES.COMMON
  },
  {
    question: 'faq_page_q_3',
    mobile: {
      answer: [
        {
          text: 'faq_page_a_3_1',
          children: [
            {
              text: 'faq_page_a_3_1_1'
            },
            {
              text: 'faq_page_a_3_1_2'
            },
            {
              text: 'faq_page_a_3_1_3'
            },
          ],
        },
        {
          text: 'faq_page_a_3_2',
          images: ['a_3_2.png']
        },
      ],
    },
    desktop: {
      answer: [
        {
          text: 'faq_page_a_3_1',
          children: [
            {
              text: 'faq_page_a_3_1_1'
            },
            {
              text: 'faq_page_a_3_1_2'
            },
            {
              text: 'faq_page_a_3_1_3'
            },
          ],
        },
        {
          text: 'faq_page_a_3_2',
          images: ['a_3_2_d.png']
        },
      ]
    },
    category: FAQ_CATEGORY_NAMES.INPUT
  },
  {
    question: 'faq_page_q_4',
    mobile: {
      answer: [
        {
          text: 'faq_page_a_4_1',
          children: [
            {
              text: 'faq_page_a_4_1_1',
            },
            {
              text: 'faq_page_a_4_1_2',
            },
            {
              text: 'faq_page_a_4_1_3',
            },
            {
              text: 'faq_page_a_4_1_4',
              images: ['a_4_1_4_1.png', 'a_4_1_4_2.png']
            },
            {
              text: 'faq_page_a_4_1_5',
            },
            {
              text: 'faq_page_a_4_1_6',
              images: ['a_4_1_6.png']
            },
          ],
        }
      ],
    },
    desktop: {
      answer: [
        {
          text: 'faq_page_a_4_1',
          children: [
            {
              text: 'faq_page_a_4_1_1',
            },
            {
              text: 'faq_page_a_4_1_2',
            },
            {
              text: 'faq_page_a_4_1_3',
              images: ['a_4_1_3_d.jpg']
            },
            {
              text: 'faq_page_a_4_1_4',
              images: ['a_4_1_4_d.jpg']
            },
            {
              text: 'faq_page_a_4_1_5',
            },
            {
              text: 'faq_page_a_4_1_6',
              images: ['a_4_1_6_d.jpg']
            },
          ],
        }
      ],
    },
    category: FAQ_CATEGORY_NAMES.INPUT
  },
  {
    question: 'faq_page_q_5',
    mobile: {
      answer: [
        {
          text: 'faq_page_a_5_1',
          children: [
            {
              text: 'faq_page_a_5_1_1',
            },
            {
              text: 'faq_page_a_5_1_2',
            },
            {
              text: 'faq_page_a_5_1_3',
            },
            {
              text: 'faq_page_a_5_1_4',
            },
            {
              text: 'faq_page_a_5_1_5',
              images: ['a_5_1_5.png']
            },
          ],
        }
      ],
    },
    desktop: {
      answer: [
        {
          text: 'faq_page_a_5_1',
          children: [
            {
              text: 'faq_page_a_5_1_1',
            },
            {
              text: 'faq_page_a_5_1_2',
            },
            {
              text: 'faq_page_a_5_1_3',
            },
            {
              text: 'faq_page_a_5_1_4',
            },
            {
              text: 'faq_page_a_5_1_5',
              images: ['a_5_1_5_1_d.png']
            },
          ],
        }
      ],
    },
    category: FAQ_CATEGORY_NAMES.OUTPUT
  },
  {
    question: 'faq_page_q_6',
    mobile: {
      answer: [
        {
          text: 'faq_page_a_6_1',
          children: [
            {
              text: 'faq_page_a_6_1_1',
            },
            {
              text: 'faq_page_a_6_1_2',
            },
            {
              text: 'faq_page_a_6_1_3',
            },
            {
              text: 'faq_page_a_6_1_4',
            },
            {
              text: 'faq_page_a_6_1_5',
            },
            {
              text: 'faq_page_a_6_1_6',
              images: ['a_6_1_6.png']
            },
          ],
        }
      ],
    },
    desktop: {
      answer: [
        {
          text: 'faq_page_a_6_1',
          children: [
            {
              text: 'faq_page_a_6_1_1',
            },
            {
              text: 'faq_page_a_6_1_2',
            },
            {
              text: 'faq_page_a_6_1_3',
            },
            {
              text: 'faq_page_a_6_1_4',
            },
            {
              text: 'faq_page_a_6_1_5',
            },
            {
              text: 'faq_page_a_6_1_6',
              images: ['a_6_1_6_d.png']
            },
          ],
        }
      ]
    },
    category: FAQ_CATEGORY_NAMES.TRANSFER
  },
  {
    question: 'faq_page_q_7',
    mobile: {
      answer: [
        {
          text: 'faq_page_a_7_1',
          children: [
            {
              text: 'faq_page_a_7_1_1',
            },
            {
              text: 'faq_page_a_7_1_2',
            },
            {
              text: 'faq_page_a_7_1_3',
            },
            {
              text: 'faq_page_a_7_1_4',
            },
            {
              text: 'faq_page_a_7_1_5',
              images: ['a_7_1_5.png']
            },
          ],
        }
      ],
    },
    desktop: {
      answer: [
        {
          text: 'faq_page_a_7_1',
          children: [
            {
              text: 'faq_page_a_7_1_1',
            },
            {
              text: 'faq_page_a_7_1_2',
            },
            {
              text: 'faq_page_a_7_1_3',
            },
            {
              text: 'faq_page_a_7_1_4',
            },
            {
              text: 'faq_page_a_7_1_5',
              images: ['a_7_1_5_d.png']
            },
          ],
        }
      ],
    },
    category: FAQ_CATEGORY_NAMES.EXCHANGE
  },
  {
    question: 'faq_page_q_8',
    mobile: {
      answer: [
        {
          text: 'faq_page_a_8_1',
          children: [
            {
              text: 'faq_page_a_8_1_1'
            },
            {
              text: 'faq_page_a_8_1_2',
              images: ['a_8_1_2.png']
            },
          ],
        }
      ],
    },
    desktop: {
      answer: [
        {
          text: 'faq_page_a_8_1',
          children: [
            {
              text: 'faq_page_a_8_1_1'
            },
            {
              text: 'faq_page_a_8_1_2',
              images: ['a_8_1_2_d.png']
            },
          ],
        }
      ],
    },
    category: FAQ_CATEGORY_NAMES.EXCHANGE
  },
  {
    question: 'faq_page_q_9',
    mobile: {
      answer: [
        {
          text: 'faq_page_a_9_1',
        },
        {
          text: 'faq_page_a_9_2',
          images: ['a_9_2.png']
        },
      ],
    },
    desktop: {
      answer: [
        {
          text: 'faq_page_a_9_1',
        },
        {
          text: 'faq_page_a_9_2',
          images: ['a_9_2_d.png']
        },
      ],
    },
    category: FAQ_CATEGORY_NAMES.EXCHANGE
  },
  {
    question: 'faq_page_q_10',
    mobile: {
      answer: [
        {
          text: 'faq_page_a_10_1',
        },
        {
          text: 'faq_page_a_10_2',
          images: ['a_10_2.png']
        }
      ],
    },
    desktop: {
      answer: [
        {
          text: 'faq_page_a_10_1',
        },
        {
          text: 'faq_page_a_10_2',
          images: ['a_10_2_d.png']
        }
      ],
    },
    category: FAQ_CATEGORY_NAMES.EXCHANGE
  },
  {
    question: 'faq_page_q_11',
    mobile: {
      answer: [
        {
          text: 'faq_page_a_11_1',
          children: [
            {
              text: 'faq_page_a_11_1_1'
            },
            {
              text: 'faq_page_a_11_1_2'
            },
            {
              text: 'faq_page_a_11_1_3'
            },
            {
              text: 'faq_page_a_11_1_4'
            },
            {
              text: 'faq_page_a_11_1_5',
              images: ['a_11_1_5_1.png', 'a_11_1_5_2.png']
            },
            {
              text: 'faq_page_a_11_1_6',
              images: ['a_11_1_6.png']
            },
            {
              text: 'faq_page_a_11_1_7',
              images: ['a_11_1_7_1.png', 'a_11_1_7_2.png']
            },
            {
              text: 'faq_page_a_11_1_8',
              images: ['a_11_1_8.png']
            },
          ],
        }
      ],
    },
    desktop: {
      answer: [
        {
          text: 'faq_page_a_11_1',
          children: [
            {
              text: 'faq_page_a_11_1_1'
            },
            {
              text: 'faq_page_a_11_1_2'
            },
            {
              text: 'faq_page_a_11_1_3'
            },
            {
              text: 'faq_page_a_11_1_4'
            },
            {
              text: 'faq_page_a_11_1_5',
              images: ['a_11_1_5_d.png']
            },
            {
              text: 'faq_page_a_11_1_6',
              images: ['a_11_1_6_d.png']
            },
            {
              text: 'faq_page_a_11_1_7',
              images: ['a_11_1_7_1_d.png', 'a_11_1_7_2_d.png']
            },
            {
              text: 'faq_page_a_11_1_8',
              images: ['a_11_1_8_d.png']
            },
          ],
        }
      ],
    },
    category: FAQ_CATEGORY_NAMES.BUY_CRYPTO
  },
  {
    question: 'faq_page_q_12',
    mobile: {
      answer: [
        {
          text: 'faq_page_a_12_1',
          children: [
            {
              text: 'faq_page_a_12_1_1'
            },
            {
              text: 'faq_page_a_12_1_2'
            },
            {
              text: 'faq_page_a_12_1_3',
              images: ['a_12_1_3.png']
            },
          ],
        }
      ],
    },
    desktop: {
      answer: [
        {
          text: 'faq_page_a_12_1',
          children: [
            {
              text: 'faq_page_a_12_1_1'
            },
            {
              text: 'faq_page_a_12_1_2'
            },
            {
              text: 'faq_page_a_12_1_3',
              images: ['a_12_1_3.png']
            },
          ],
        }
      ],
    },
    category: FAQ_CATEGORY_NAMES.CASH
  },
  {
    question: 'faq_page_q_13',
    mobile: {
      answer: [
        {
          text: 'faq_page_a_13_1',
          children: [
            {
              text: 'faq_page_a_13_1_1'
            },
            {
              text: 'faq_page_a_13_1_2'
            },
            {
              text: 'faq_page_a_13_1_3',
              images: ['a_13_1_3.png']
            },
          ],
        }
      ],
    },
    desktop: {
      answer: [
        {
          text: 'faq_page_a_13_1',
          children: [
            {
              text: 'faq_page_a_13_1_1'
            },
            {
              text: 'faq_page_a_13_1_2'
            },
            {
              text: 'faq_page_a_13_1_3',
              images: ['a_13_1_3.png']
            },
          ],
        }
      ],
    },
    category: FAQ_CATEGORY_NAMES.CASH
  },
]

export const CONSTRAINT_TYPE_NAMES = {
  AUTOEXCHANGE: 'AUTOEXCHANGE'
}

export const TOKEN_TYPE_NAMES = {
  EthereumFork: 'EthereumFork',
  BitcoinFork: 'BitcoinFork',
  Fiat: 'Fiat',
  FiatCash: 'FiatCash',
  Other: 'Other'
}

export const TOKEN_TYPES = Object.keys(TOKEN_TYPE_NAMES)
