export const ORDER_TYPE_NAMES = {
  LIMIT: 'LIMIT',
  MARKET: 'MARKET'
}

export const ORDER_TYPES = [
  {
    value: ORDER_TYPE_NAMES.MARKET,
    title: 'ae_market_order'
  },
  {
    value: ORDER_TYPE_NAMES.LIMIT,
    title: 'ae_limit_order'
  }
]

export const MIN_CURRENCIES_VALUE = {
  BTC: '0.001',
  LTC: '0.1',
  ETH: '0.01',
  USDT: '5',
  DOGE: '100',
  LION: '1000',
}

export const AE_OPERATION_TYPE_NAMES = {
  AUTOEXCHANGE: 'Autoexchange',
  AUTOEXCHANGE_CASH: 'AutoexchangeCash',
  AUTOEXCHANGE_BILL: 'AutoexchangeBill',
}

export const AE_OPERATION_TYPES = Object.values(AE_OPERATION_TYPE_NAMES)
